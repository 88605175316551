import React,{useState,useEffect,useContext} from "react"
import { graphql } from "gatsby"
import Footer from "../components/footer"

import TransitionLink from 'gatsby-plugin-transition-link'
import SEO from "../components/seo"
import {TransitionContext} from '../context/transitionContext';
const Servicios= ({data})=>{
  const [selected,setselected] = useState("todos")
  const [dataList,setdataList] = useState([])
  const {updateHeader} = useContext(TransitionContext)
    useEffect(() => {
      const dataQuery = data.allStrapiProyectos.edges
      setdataList(dataQuery)
    }, [])
    const filas = (element:{portada:[{url:String}],title:string,id:string})=>{
                const id = (element.id).toLowerCase();
                const title =(element.title.replace(/ /g,"-")).toLowerCase();
              return(
              <>
              <TransitionLink  to={`/${id}/${title}`} className="projects"  entry={{trigger: () =>updateHeader() }}>
                <div className="padre">
                  <img src={`${element.portada[0].url}`}/>
                  <div className="black"/>
                  <h1>{element.title}</h1>
                </div>
              </TransitionLink>
              </>
              )
    }
    const click = (clicked:string,id:string)=>{
      const filters = document.getElementsByClassName("filter")
      for (let k = 0; k < filters.length; k++) {
          if(filters[k].id===`${id}`){
            filters[k].classList.add("negrita");
          }else{
            filters[k].classList.remove("negrita");
          }
      }
      setselected(clicked)
    }
    return(
        <>
            <SEO title="Proyectos" />
            <div id="proyectoArriba">
                <h1 >Proyectos</h1>
                <p>Mira aqui el fruto de nuestro trabajo:</p>
            </div>
            <div className="filters" id="filters">
                      <p onClick={()=>click("todos","Todos")} id="fTodos" className="filter negrita">Todos</p>
                      <p onClick={()=>click("Automatización industrial","fAutomatizacion")} id="fAutomatizacion" className="filter">Automatizacíon industrial</p>
                      <p onClick={()=>click("Energías renovables","fEnergias")} id="fEnergias" className="filter">Energias renovables</p>
                      <p onClick={()=>click("Diseño Industrial","fIndustrial")} id="fIndustrial" className="filter">Diseño industrial</p>
                      <p onClick={()=>click("Ergonomía","fErgonomia")} id="fErgonomia" className="filter">Elevacion ergonomia</p>
                      <p onClick={()=>click("Básculas","fBasculas")} id="fBasculas" className="filter">Basculas camioneras</p>
                      <p onClick={()=>click("Desarrollo de software","fSoftware")} id="fSoftware" className="filter">Desarrollo de software</p>
              </div>
          <div id="proyecto">
            <div className="project">
              {dataList.map(e=>{
                if(selected !== "todos"){
                  if(e.node.categories.findIndex(f=>f.category === selected)!==-1){
                    return(filas(e.node))
                  }
                }else {
                  return(filas(e.node))
                }
                })}
            </div>
          </div>
          <Footer/>
        </>
    )
}

export default Servicios
export const pageQuery = graphql`
query{
  allStrapiProyectos {
    edges {
      node {
        id
        title
        portada{
          url
        }
        categories {
          category
        }
      }
    }
  }
}
`